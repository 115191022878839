.pagination {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
}

.pagination a {
	cursor: pointer;
	padding: 10px;
	margin: 0 5px;

	color: #333;
	text-decoration: none;
}

.pagination a:hover {
	background-color: #f5f5f5;
}

.pagination .active a {
	background-color: #fff;
	color: #000;
	border-radius: 2px;
}

.pagination .break a {
	cursor: default;
	pointer-events: none;
}

.pagination .disabled a {
	color: #ccc;
	pointer-events: none;
	cursor: default;
}
