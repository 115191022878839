.HeaderWrapper {
	min-height: 72px;
	max-height: 150px;
	width: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	transition:
		transform 0.3s ease-in-out,
		opacity 0.3s ease-in-out;
	position: sticky !important;
	top: 0;

	&.HeaderBlock {
		transform: translateY(-100%);
		opacity: 0;
	}

	&.HeaderFixed {
		transform: translateY(0);
		opacity: 1;
		z-index: 5;
	}

	.shoppingCart {
		position: absolute;
		right: 20px;

		cursor: pointer;
		.cartCounter {
			display: flex;
			width: 17px;
			height: 17px;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-radius: 8.5px;
			background: #fff;
			position: absolute;
			top: -10px;
			right: -9px;
		}
	}

	.shoppingCartDetail {
		position: absolute;
		top: 60px;
		right: 36px;
		z-index: 999;
	}
	.shoppingCartDetailMobile {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;

		background-color: rgba(0, 0, 0, 0.7);
		padding: 20px;
		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.LogoLeft {
	margin-left: 72px;
}

.Logo {
	max-height: 60px;
	max-width: 80px;
}
.LogoMobile {
	margin-left: 10px;
	max-height: 40px;
}

.activeLink {
	color: #000;
	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
}

.inactiveLink {
	color: #37d514;

	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-decoration-line: none;
}
