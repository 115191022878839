.PlayerContainer {
  width: 100%;
  min-height: 650px;
  height: 50vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  .IframeWrapper {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (max-width: 1400px) {
  .PlayerContainer {
    padding: 0px 30px;
  }
}

@media (max-width: 981px) {
  .PlayerContainer {
    padding: 0px;
    min-height: auto;
    height: 100vh;
    .IframeWrapper {
      height: 100vh;
    }
  }
}