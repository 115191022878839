.scrollingTextContainer {
	width: 100%;
	height: 40px;
	overflow: hidden;
	cursor: pointer;
	margin-bottom: 10px;
	position: relative;
}

.scrollingTextWrapper {
	display: flex;
	white-space: nowrap;
	animation: scrollText 20s linear infinite;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	align-items: center;
}

.scrollingText {
	display: flex;
	align-items: center;
}

.icono {
	margin-left: 8px;
}

@keyframes scrollText {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}
