.FeaturedProductsWidgetWrapper {
	width: 100%;
	min-height: 402px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	gap: 32px;
	padding-bottom: 32px;
	h1 {
		font-size: 24px;
	}
	.ProductsList {
		max-width: 90vw;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}
}

.FeaturedProductItem {
	width: 200px;
	height: 390px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 14px;

	.Image {
		width: 200px;
		height: 200px;
		background-color: #ede7e2;
		border-radius: 6px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	@media (max-width: 500px) {
		.Image {
			width: 150px;
			align-items: center;
		}
	}
	.Title {
		width: 100%;
		font-size: 16px;
		height: 2.4em;
		text-align: center;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.2em;
		max-height: 2.4em; /* Adjust the number of lines as needed */
	}
	.Price {
		font-weight: 600;
		font-size: 14px;
		display: flex;
		justify-content: center;
	}
	.Buttons {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 14px;
		.QtyButton,
		.AddButton {
			height: 42px;
			border-radius: 8px;
			border: 1px solid;
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
		}
		.QtyButton {
			width: 65%;
			justify-content: space-between;
			overflow: hidden;
			font-size: 16px;
			border-color: #d5dde0;
			padding-left: 5px;
			padding-right: 5px;
			button {
				height: 100%;
				background-color: transparent;
				border: none;
				cursor: pointer;
				font-size: 16px;
				font-weight: 600;
			}
			.qtyNumber {
				cursor: default;
				height: 100%;
				display: flex;
				align-items: center;
				padding-bottom: 5px;
			}
		}
		.AddButton {
			width: 100%;
			justify-content: center;
			font-size: 16px;
			font-weight: 600;
			background: #fff;
			color: #000;
			&.Pressed {
				background-color: #000;
				color: #fff;
			}
		}
		.AddButton:active {
			background: #f5f5f5;
			border: 2px solid #f5f5f5;
		}
	}

	@media (max-width: 720px) {
		.Buttons {
			flex-direction: column;
			align-items: center;

			.AddButton {
				width: 100%; /* Ajustar el ancho al 100% en pantallas <= 720px */
			}
		}
	}
}
@media (max-width: 400px) {
	.FeaturedProductItem {
		width: 150px;
	}
}
.mySwiper {
	padding-bottom: 40px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mySlide {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
