.ShoppingCartWrapper {
	max-height: calc(100% - 42px);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
	gap: 8px;
	border-radius: 8px;
	background: #fff;

	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

	&.WrapperWeb {
		position: relative;
		width: 400px;
		height: 100%;
	}

	&.WrapperMobile {
		width: 90%;
	}
	.emptyContainer {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-content: center;
		align-items: center;

		.infoText {
			color: #d5dde0;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
		}
	}
	.Items {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-right: 8px;
		padding-left: 8px;
		max-height: 85%;
		overflow-x: hidden;
		overflow-y: scroll;
		align-items: center;
		max-height: 400px;
	}

	.Bottom {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-right: 8px;
		padding-left: 8px;
		padding-bottom: 8px;
		.Subtotal {
			box-sizing: border-box;
			background-color: #f7f8f9;
			border-radius: 8px;
			width: 100%;
			height: 30px;
			font-size: 12px;
			font-weight: 300;
			display: flex;
			justify-content: space-between;
			padding: 0 12px;
			align-items: center;
			.subtotalPrice {
				font-weight: 600;
			}
		}

		button {
			background: var(--yellow-200, #ffde07);
			border: none;
			width: 100%;
			height: 40px;
			border-radius: 8px;
			display: flex;
			flex-direction: row;
			gap: 8px;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
		}
	}
	.FiraCartItemWrapper {
		border-bottom: 1px solid #d5dde0;
		width: 100%;
		min-height: 82px;
		display: grid;

		grid-template-rows: 34px 24px;
		grid-template-columns: 65px auto;
		gap: 8px;
		grid-template-areas:
			"image info"
			"image actions";
		padding-top: 8px;
		#gridImage {
			grid-area: image;
			.imageContainer {
				width: 100%;
				height: 100%;
				border-radius: 6px;
				background-color: #e5e5e6;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		#gridInfo {
			grid-area: info;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 16px;
			align-items: flex-start;
			.infoTitle {
				font-size: 14px;
				font-weight: 400;
				line-height: normal;
				max-width: 133px;
				width: 220px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.deleteIcon {
				display: flex;
				height: 100%;
				justify-content: center;
				align-items: flex-start;
				cursor: pointer;
				padding-right: 10px;
			}
		}

		#gridActions {
			grid-area: actions;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 16px;
			align-items: center;
			.price {
				font-size: 14px;
				font-weight: 600;
				color: #f16063;
			}
			.inputWrapper {
				padding-right: 8px;
				input {
					width: 71px;
					height: 24px;
					padding: 8px 12px;
					box-sizing: border-box;
					border: 1px solid #d0d0d0;
					border-radius: 4px;
					font-family: "Montserrat", sans-serif;
					font-size: 14px;
					text-align: center;
				}
			}
		}
	}
}

.InfoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #010202;

	gap: 16px;

	.LandingCounter {
		max-width: 120px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #d0d0d0;
		margin-bottom: 8px;
		&.MobileInfo {
			width: 105px;
		}
		.control {
			width: 27px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
		}
	}
}

.ItemCart {
	width: 100%;
	height: 42px;
	display: flex;
	box-sizing: border-box;
	align-items: center;
	padding-left: 24px;
	padding-top: 14px;
	gap: 10px;
	position: relative;
}
.indicatorCart {
	width: 100%;
	height: 5px;
	background-color: var(--yellow-200, #ffde07);
	border-radius: 6px;

	bottom: 0;
}
