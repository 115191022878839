.MainWrapper {
	overflow-y: auto;
	display: grid;
	grid-template-rows: auto 1fr auto;
	position: relative;
	.ContentWrapper {
		padding: 24px 5vw;
		display: grid;
		gap: 24px;
	}
}

@media (max-width: 1400px) {
	.MainWrapper .ContentWrapper {
		padding: 24px 10px;
	}
}

@media (max-width: 981px) {
	.MainWrapper .ContentWrapper {
		padding: 0px;
	}
}
