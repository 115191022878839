.FiraTagWrapper {
	position: fixed;
	top: 15vh;
	border-radius: 0px 8px 8px 0px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	z-index: 1;
	a {
		writing-mode: vertical-lr;
		text-orientation: sideways;
		cursor: pointer;
		margin: 0;
		font-style: normal;
		letter-spacing: -0.408px;
		text-decoration-line: underline;
		color: black;
		.FIRA {
			font-weight: 600;
		}
	}
	padding: 7px 10px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 9px;
	.Logo {
		transform: rotatez(90deg);
	}
}

@media (max-width: 981px) {
	.FiraTagWrapper {
		display: none;
	}
}
