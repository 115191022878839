@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// MAIN COLOR
$primary-color: #ffde07;

// BACKGROUND
$main-background: #f7f8f9;
$dark-background: #000000;
$dark-secondary-background: #010202;
$light-background: #ffffff;

// TEXT
$main-text: #000000;
$gray-text: #f7f8f9;
$light-text: #ffffff;

// SYSTEM
$success-color: #66cb9f;
$danger-color: #f16063;
$darker-gray-color: #4b4c4c;
$dark-gray-color: #d0d0d0;
$medium-gray-color: #d5dde0;
$light-gray-color: #c4c4c4;

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-style: normal;
	font-weight: bold;
	margin: 0;
}

h1,
.h1 {
	font-size: 40px;
	line-height: 54px;
}

h2,
.h2 {
	font-size: 32px;
	line-height: 44px;
}

h3,
.h3 {
	font-size: 28px;
	line-height: 38px;
}

h4,
.h4 {
	font-size: 24px;
	line-height: 32px;
}

h5,
.h5 {
	font-size: 20px;
	line-height: 28px;
}

h6,
.h6 {
	font-size: 16px;
	line-height: 22px;
}

.lead-text,
.body-16-text,
.body-14-text,
.blockquote-text,
.capitalized-text,
.small-text,
.tiny-text {
	font-style: normal;
}

.lead-text {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	overflow-wrap: anywhere;
	word-break: normal;
}

.lead-10-text {
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
}

.lead-8-text {
	font-weight: 500;
	font-size: 8px;
	line-height: 11px;
}

.body-16-text {
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
}

.body-14-text {
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
}

.body-12-low-text {
	font-weight: 500;
	font-size: 12px;
	line-height: 14.63px;
}

.blockquote-text {
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
}

.capitalized-text {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.small-text {
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
}

.tiny-text {
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
}

.tiny-text-8 {
	font-weight: 400;
	font-size: 8px;
}

.alert-text,
.alert-text-12 {
	font-weight: 400;
	line-height: 14px;
}

.alert-text-bold,
.alert-text-12-bold {
	font-weight: 700;
	line-height: 14px;
}

.alert-text,
.alert-text-bold {
	font-size: 10px;
}

.alert-text-12,
.alert-text-12-bold {
	font-size: 12px;
}

.alert-text-14-bold {
	font-size: 14px;
	font-weight: 600;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: $main-background;
}

::-webkit-scrollbar-thumb {
	background-color: $dark-gray-color;
	border-radius: 10px;
}

p {
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

$volumen_bar_height: 5px;
$volumen_bar_thumb_size: 10px;
$video_controls_bg_color: rgba(0, 0, 0, 0.25);
