.FooterWrapper {
	padding-top: 48px;
	padding-bottom: 30px;
	box-sizing: border-box;
	min-height: 200px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	.LogosWrapper {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		gap: 40px;
		align-items: center;
		.Logo {
			justify-self: end;
		}
		.Logo,
		.FiraLogo {
			height: 50px;
		}
	}
	.TextWrapper {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}
	.TermsText {
		cursor: pointer;
		text-align: center;
		font-style: normal;
		line-height: 22.1px;
	}
	.RightsText {
		text-align: center;
		font-style: normal;
		line-height: 23.8px;
	}
}

.Separetor {
	height: 70px;
	border: 1px solid;
}
