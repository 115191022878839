/* Catalogo.css */
.catalogoMain {
	display: flex;
	justify-content: center;
}
.catalogoContainer {
	max-width: 1350px;
	margin: 0 auto;
	margin-top: 48px;
	margin-left: 48px;
	margin-right: 48px;
	width: 100%;
}

.gridContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: 20px;
	justify-items: center;

	@media (min-width: 900px) {
		grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
		/* Otra opción si calc(25% - 20px) no funciona: minmax(200px, calc(1 / 4 * 100% - 20px)) */
	}
}

.producto {
	border: 1px solid #ccc;
	padding: 10px;
	text-align: center;
}

.WrapperPaginacion {
	display: flex;
	width: 100%;
	justify-content: center;
}
.paginacion {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	border-radius: 10px;
	background: #f5f5f5;

	margin-bottom: 20px;

	padding-left: 15px;
	padding-right: 15px;
}

.paginacion span {
	cursor: pointer;
	padding: 5px 10px;
	margin: 0 5px;
	border-radius: 3px;
}

.paginaActiva {
	background-color: #ffffff;
	color: #000000;
}

.filterBar {
	display: flex;
	flex-direction: row;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 30px;
	margin-bottom: 30px;
	gap: 18px;
	justify-content: space-between;
	.titleFilter {
		color: #232323;

		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px; /* 157.143% */
		letter-spacing: 0.24px;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.mobileFilterButton {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		cursor: pointer;
	}
}
.inputSearch {
	display: flex;
	padding: 8px 39px 8px 21px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1 0 0;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid #c7c7c7;
	background: #fff;
}

.orderingContainer {
	display: flex;
	flex-direction: row;
	border: 1px solid #c7c7c7;
	border-radius: 6px;
	margin-left: 10px;
	padding: 10px 16px;
	align-items: center;
	position: relative;
	.orderingTitle {
		width: 185px;
		height: 24px;
		display: flex;
		gap: 21px;
		cursor: pointer;
		align-items: center;
	}

	.orderingOptions {
		background-color: #fff;
		box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
		position: absolute;
		width: calc(100% - 16px);
		z-index: 20;
		padding: 8px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		gap: 8px;
		display: flex;
		flex-direction: column;
		padding-top: 16px;
		top: 40px;
		left: -1px;
		color: #000;
		border: 1px solid #c7c7c7;
		cursor: pointer;
		.insideOption {
			border: 1px solid #c7c7c7;
			border-radius: 6px;
			padding: 4px 12.5px;
			display: flex;
			justify-content: center;
			cursor: pointer;
			font-size: 12px;
			text-align: center;
		}
	}
	/* Estilos para el menú móvil lateral */
}

.mobileMenu {
	position: fixed;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background-color: #fff;
	z-index: 1001;
	overflow-y: auto;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	gap: 25px;
}
.opacityOverlay {
	background: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100%;
	right: 0px;
	position: fixed;
	top: 0;
	z-index: 2;
}
.mobileMenu.open {
	transform: translateX(0);
}

.slideInLeft {
	animation: slideInLeft 0.2s ease-out;
}

@keyframes slideInLeft {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

.slideOutLeft {
	animation: slideOutLeft 0.8s ease-out;
}

@keyframes slideOutLeft {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-10%);
	}
}
.closeMobileMenu {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #c7c7c7;
	height: 40px;
	cursor: pointer;
}

.orderingPriceContainer {
	display: flex;
	flex-direction: row;
	border: 1px solid #c7c7c7;
	border-radius: 6px;
	margin-left: 10px;
	padding: 10px 16px;
	align-items: center;
	position: relative;
	.orderingPriceTitle {
		height: 24px;
		display: flex;
		gap: 21px;
		cursor: pointer;
		align-items: center;
	}

	.priceOptions {
		display: flex;
		flex-direction: row;
		height: 120px;
		background-color: #fff;
		box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
		position: absolute;
		width: calc(100% - 16px);
		z-index: 20;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		border: 1px solid #c7c7c7;
		top: 43px;
		width: 365px;
		left: 0;
		padding: 20px;
		cursor: pointer;
		gap: 30px;
		.itemOption {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
}
